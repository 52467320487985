var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.assessments,"headers":_vm.headers,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [5, 10, 25, 50, 100],
    },"server-items-length":_vm.total,"loading":_vm.loading,"calculate-widths":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.survey.name)+" ")]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.data.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"info mr-1"},on:{"click":function($event){_vm.quickViewing = item}}},[_vm._v("Quick View")])]}}],null,true)}),(_vm.quickViewing)?_c('v-dialog',{attrs:{"value":true,"width":"650px"},on:{"input":function($event){_vm.quickViewing = null}}},[_c('assessment-view',{attrs:{"initAssessment":_vm.quickViewing},on:{"close":function($event){_vm.quickViewing = null},"deleted":function($event){(_vm.quickViewing = null), _vm.fetchAssessments()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }