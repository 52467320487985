











import CreateAssessment from './CreateAssessment.vue';
import { Patient } from '@/models';
import { PropType, defineComponent, ref } from '@/plugins/composition';

export default defineComponent({
  components: { CreateAssessment },
  name: 'CreateAssessmentDialog',
  props: {
    patient: {
      type: Object as PropType<Patient>,
    },
  },

  setup(props, { emit }) {
    const dialog = ref(false);

    const complete = () => {
      dialog.value = false;
      emit('input');
    };

    return {
      dialog,
      complete,
    };
  },
});
