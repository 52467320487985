




















import CreateAssessmentDialog from '../../../assessments/components/CreateAssessmentDialog.vue';
import CreateSeverityScale from '../../../bookings/views/ViewBookingPage/components/CreateSeverityScale.vue';
import PatientAssessmentsTable from '../../components/PatientAssessmentsTable.vue';
import SeverityScaleSection from '../../../bookings/views/ViewBookingPage/components/SeverityScaleSection.vue';
import SeverityScaleTable from '../../../bookings/views/ViewBookingPage/components/SeverityScaleTable.vue';
import UsersViewAllLayout from '../../../shared/layouts/UsersViewAllLayout.vue';
import { Patient } from '@/models';
import { defineComponent, onMounted, ref, watch } from '@/plugins/composition';
import { useRouter } from '@/router';

export default defineComponent({
  components: {
    UsersViewAllLayout,
    CreateAssessmentDialog,
    PatientAssessmentsTable,
    CreateSeverityScale,
    SeverityScaleTable,
    SeverityScaleSection,
  },
  name: 'PatientAssessmentsPage',
  setup() {
    const showAssessment = ref(false);
    const newAssessment = ref(false);
    const refreshRequired = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const assessmentsTable = ref<any>(null);

    const patient = ref<Patient | null>();

    const { route } = useRouter();

    onMounted(async () => {
      patient.value = await Patient.findOrFail(route.value.params.uuid);
    });

    const refresh = () => {
      assessmentsTable.value.fetchAssessments();
    };

    watch(newAssessment, () => refresh());

    return {
      showAssessment,
      newAssessment,
      patient,
      assessmentsTable,
      refresh,
      refreshRequired,
    };
  },
});
