




























import PatientSelectInput from '@/components/inputs/PatientSelectInput.vue';
import SurveyQuestion from './SurveyQuestion.vue';
import { AnswerData, Assessment, Patient, Practitioner, Question } from '@/models';
import { PropType, defineComponent, onMounted, ref } from '@/plugins/composition';
import { confirmation } from '@/domains/shared/components/confirm-dialog/confirm';
import { routeNames } from '@/router/routeNames';
import { storage } from '@/main';
import { useRouter } from '@/router';

export default defineComponent({
  components: { SurveyQuestion, PatientSelectInput },
  name: 'AssessmentView',
  props: {
    initAssessment: {
      type: Object as PropType<Assessment>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const questions = ref<Question[]>([]);
    const valid = ref(false);
    const assessment = ref<Assessment>();
    const creating = ref(false);
    const changingPatient = ref(false);
    const practitioner = ref<Practitioner | null>(null);

    const { router } = useRouter();

    onMounted(async () => {
      await loadQuestions();
      practitioner.value = await storage.auth.practitioner();
      mapAnswers(props.initAssessment);
    });

    const loadQuestions = async () => {
      creating.value = true;
      questions.value = await props.initAssessment.survey.questions().getAll();
      creating.value = false;
    };

    const setAnswer = (event: string, question: Question) => {
      question.tempData.answer = event;
    };

    const mapAnswers = (assessment: Assessment) => {
      assessment.data.answers.map((answer: AnswerData) => {
        const question = questions.value.find((question: Question) => question.uuid === answer.question);
        if (question) {
          question.tempData.answer = answer.value;
        }
      });
    };

    const destroy = async () => {
      const confirm = await confirmation('Are you sure you want to delete this assessment?', { confirmType: 'warn' });

      if (confirm) {
        await props.initAssessment.delete();
        emit('deleted');
      }
    };

    const changePatient = () => {
      changingPatient.value = true;
    };

    const updatePatient = async (patient: Patient) => {
      const confirm = await confirmation(`Are you sure you want to move this assessment to ${patient.name}'s profile?`);

      if (confirm) {
        const response = await props.initAssessment.directUpdate({
          patient: patient.uuid,
        });

        if (response) {
          router.push({ name: routeNames.practitioner.patients.view.assessments, params: { uuid: patient.uuid } });
        }
      }
    };

    return {
      questions,
      setAnswer,
      valid,
      creating,

      assessment,
      destroy,
      emit,
      changePatient,
      practitioner,
      updatePatient,
      changingPatient,
    };
  },
});
